import { entidadeTipoEnum, permissoesEnum } from "@/types/enums";

export default defineNuxtRouteMiddleware((to) => {
  const { $ability } = useNuxtApp();

  const routeName = String(to.name);
  const id = Number(to.params.id);

  // página de listagem
  if (
    routeName === "usuarios" &&
    $ability.cannot(permissoesEnum.VER_ROTA, entidadeTipoEnum.REDE) &&
    $ability.cannot(permissoesEnum.VER_ROTA, entidadeTipoEnum.LOJA) &&
    $ability.cannot(permissoesEnum.VER_ROTA, entidadeTipoEnum.SOCIO_HONORARIO)
  ) {
    return navigateTo({
      name: "index",
    });
  }

  // página de cadastro
  if (
    routeName === "usuarios-cadastrar" &&
    $ability.cannot(permissoesEnum.CADASTRAR_USUARIO, entidadeTipoEnum.REDE) &&
    $ability.cannot(permissoesEnum.CADASTRAR_USUARIO, entidadeTipoEnum.LOJA) &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_USUARIO,
      entidadeTipoEnum.SOCIO_HONORARIO
    )
  ) {
    return navigateTo({
      name: "usuarios",
    });
  }

  // página de visualização
  if (
    routeName === "usuarios-visualizar-id" &&
    $ability.cannot(permissoesEnum.VISUALIZAR_USUARIO, entidadeTipoEnum.REDE) &&
    $ability.cannot(permissoesEnum.VISUALIZAR_USUARIO, entidadeTipoEnum.LOJA) &&
    $ability.cannot(
      permissoesEnum.VISUALIZAR_USUARIO,
      entidadeTipoEnum.SOCIO_HONORARIO
    )
  ) {
    return navigateTo({
      name: "usuarios",
    });
  }

  // página de edição
  if (
    routeName === "usuarios-editar-id" &&
    $ability.cannot(permissoesEnum.EDITAR_USUARIO, entidadeTipoEnum.REDE) &&
    $ability.cannot(permissoesEnum.EDITAR_USUARIO, entidadeTipoEnum.LOJA) &&
    $ability.cannot(
      permissoesEnum.EDITAR_USUARIO,
      entidadeTipoEnum.SOCIO_HONORARIO
    ) &&
    $ability.cannot(
      permissoesEnum.CONVIDAR_PERMISSIONAR_USUARIO,
      entidadeTipoEnum.REDE
    ) &&
    $ability.cannot(
      permissoesEnum.CONVIDAR_PERMISSIONAR_USUARIO,
      entidadeTipoEnum.LOJA
    ) &&
    $ability.cannot(
      permissoesEnum.CONVIDAR_PERMISSIONAR_USUARIO,
      entidadeTipoEnum.SOCIO_HONORARIO
    )
  ) {
    return navigateTo({
      name: "usuarios-visualizar-id",
      params: {
        id,
      },
    });
  }
});
